<template>
    <div>
        <h4 class="p-b-5">投放规格：</h4>
        <Row>
            <i-col class="p-b-5">
                <RadioGroup v-model="times" type="button" size="small" >
                  <template v-if="publisherId===21">
                    <Radio label="15秒"></Radio>
                  </template>
                  <template v-else>
                    <Radio label="5秒"></Radio>
                    <Radio label="10秒"></Radio>
                    <Radio label="30秒"></Radio>
                    <Radio label="60秒"></Radio>
                  </template>
                  <Radio label="自定义时长"></Radio>
                </RadioGroup>
            </i-col>
        </Row>
        <Row v-if="times==='自定义时长'" class="p-b-5">
            <InputNumber v-show="times==='自定义时长'" v-model="customTims" size="small" :min="1" :max="10000"></InputNumber>
            <span v-show="times==='自定义时长'"> X 5秒 (最少5的倍数)</span>
        </Row>

        <h4 class="p-b-5 p-t-5">设置投放方式</h4>
        <row v-for="(item,index) in productSkuData" :key="index" class="p-b-5">
            <i-col span="24" class="title">
                {{item.productskuName}}
            </i-col>
            <i-col span="12">
              <InputNumber v-model="item.quantity" size="small" :min="0"></InputNumber> 次
            </i-col>
            <i-col span="12" class="remark">单价：{{formatMoney(item.usePrice)}} / {{item.duration}}秒</i-col>
        </row>

        <Row :gutter="4" class="p-t-10 p-r-20 text-right">
            <i-col span="24">
              <Button type="success" size="small" :disabled="isSubmit" :loading="spinShow" @click="handleAddOrder">一键加入</Button>
            </i-col>
        </Row>

    </div>
</template>

<script>
import { getProductAndSkuList } from '@/api/product/productsku'
import { adddtProductSkus } from '@/api/order/orderitem'

import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      times: this.$store.getters.token.userInfo.publisherId === 21 ? '15秒' : '5秒',
      customTims: 1,
      query: {
        orderId: -1,
        assetIds: '', // 资产主键
        categoryId: null, // 产品类别主键
        productId: null,
        schedules: [], // 订单采购档期
        pageNumber: 1, // 当前页
        keyword: '',
        pageSize: 100 // 页大小
      },
      productSkuData: [],
      defaultProduct: null,
      spinShow: false
    }
  },
  methods: {
    initPageData (searchBean) {
      this.query.orderId = searchBean.orderId
      this.query.assetIds = JSON.stringify(searchBean.assetIds) // 资产主键
      this.query.categoryId = searchBean.categoryId // 产品类别主键
      this.query.productId = searchBean.productId
      this.query.schedules = JSON.stringify(searchBean.schedules) // 订单采购档期
      this.query.pageNumber = 1
      this.query.keyword = ''

      this.defaultProduct = {
        startDate: searchBean.schedules[0].startDate,
        endDate: searchBean.schedules[0].endDate
      }
      // 加载所有可用的产品集合
      this.SeachResource()
      // 更新地图显示站点和线路
      const params = {
        data: [],
        checkedSchedules: searchBean.schedules,
        assetIds: searchBean.assetIds,
        condition: {}
      }
      this.$emit('on-updated-map', 'full', params)
    },
    SeachResource () {
      getProductAndSkuList(this.query).then(res => {
        this.productSkuData = res.list.map(x => {
          x.quantity = this.publisherId === 21 ? 85 : 0
          return x
        })
      })
    },
    handleAddOrder () {
      this.spinShow = true
      const productsku = this.productSkuData.map(sku => {
        return {
          productskuId: sku.productskuId,
          quantity: sku.quantity
        }
      })

      const GenerationCondition = {
        orderId: this.query.orderId,
        assetIds: this.query.assetIds, // 线路集合
        productskus: JSON.stringify(productsku), // 采购的数量集合
        schedules: this.query.schedules,
        duration: this.duration
      }

      adddtProductSkus(GenerationCondition).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            title: '资源添加成功!',
            desc: '请在页面右侧【采购清单】中查看购买详情！'
          })
          // // 清空页面状态选中值
          this.productSkuData.forEach(element => {
            element.quantity = this.publisherId === 21 ? 85 : 0
          })
          // // 默认选中编辑状态
          this.$emit('on-update-order', this.defaultProduct)
        }
        this.spinShow = false
      }).catch(() => { this.spinShow = false })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    duration: function () { // 获取播放时长
      let time = 0
      if (this.times === '自定义时长') {
        time = this.customTims * 5
      } else {
        time = parseInt(this.times.split('秒')[0])
      }
      return time
    },
    isSubmit () {
      let totalDuration = 0
      this.productSkuData.forEach(element => {
        totalDuration += element.quantity
      })

      return totalDuration === 0
    }
  }
}
</script>
